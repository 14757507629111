




















































import { Component, Prop, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { Api } from "@/api";
import { InfoModal } from "@/components";
import { namespace as authNs } from "@/store/auth";
import { ClientSideError } from "@/types";

const signedInText =
  "If the email is registered, you'll receive shortly an email with the link you can use for signing in.";

@Component({
  components: {
    InfoModal,
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
})
export default class SignIn extends Vue {
  @Prop({ type: String, required: false }) private params!: string;
  private email = "";

  private requesting = false;
  private signingIn = false;
  private infoModalVisible = false;
  private infoModalProps = { title: signedInText };

  private async mounted(): Promise<void> {
    this.$store.dispatch("signout");
    if (this.params) {
      this.signingIn = true;
      await this.signInWithCode();
    }
  }

  private async signInWithCode(): Promise<void> {
    try {
      const [email, message, sig] = this.params.split("::");
      const signatureBase64: string = decodeURI(sig);
      await this.$store.dispatch(`${authNs}/exchangeCapabilityCodeToJWTToken`, {
        email,
        message,
        signatureBase64,
      });
      this.$router.push({ name: "dashboard" });
    } catch (error) {
      this.signingIn = false;
      this.showErrorModalWithAppropriateMessage(error);
    }
    this.requesting = false;
  }

  private async onRequestLinkClick(): Promise<void> {
    this.$v.$touch();
    this.validateEmail() ? await this.requestJwtToken() : null;
  }

  private async requestJwtToken(): Promise<void> {
    this.requesting = true;
    try {
      await Api.requestAuthentication({ email: this.email });
      this.infoModalVisible = true;
    } catch (error) {
      this.showErrorModalWithAppropriateMessage(error);
    }
    this.requesting = false;
  }

  private get validEmail(): boolean | null {
    if (this.$v.$dirty) {
      return this.validateEmail();
    } else {
      return null;
    }
  }

  private validateEmail(): boolean {
    return !this.$v.email.$invalid;
  }

  private showErrorModalWithAppropriateMessage(error: ClientSideError): void {
    switch (error.status) {
      case 403:
      case 409: {
        this.infoModalProps.title =
          "This e-mail does not exist in our system. Please sign up first!";
        break;
      }
      default:
        this.infoModalProps.title = error.message;
    }
    this.infoModalVisible = true;
  }
}
